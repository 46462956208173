const DEFAULT_X_HEADER = {
  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
};

const DEFAULT_JSON_HEADER = {
  "Content-Type": "application/json",
};

// if (isTestSever){
//   PROD_URL = 'http://m.fourzh.com';//測試伺服器環境
// }
// let BASE_URL = "https://qcommerce-user-api.jbangit.com"
let DEV_URL = "https://delivery-dev-user-api.dynacloud.site"; // 測試地址
let PROD_URL = "https://delivery-user-api.family.com.tw";  // 正式地址
let BASE_URL = "";
let DOWN_URL = "";
if (process.env.NODE_ENV == "development") {
  DEV_URL = "/test";
  BASE_URL = DEV_URL;
  DOWN_URL = 'https://qcommerce-user-api.jbangit.com';
} else if (process.env.NODE_ENV == "testing") {
  BASE_URL = DEV_URL;
  DOWN_URL = DEV_URL;
} else if (process.env.NODE_ENV == "preview") {
  BASE_URL = 'https://delivery-dev-user-api.dynacloud.site';
  DOWN_URL = 'https://delivery-dev-user-api.dynacloud.site';
} else {
  BASE_URL = PROD_URL;
  DOWN_URL = PROD_URL;
}

// let LOCAL_URL = 'http://192.168.199.128:8536';
// let LICENSE_URL = 'http://cms-api2.lskiot.cn';

// const BASE_URL = process.env.NODE_ENV === "production" ? PROD_URL : DEV_URL;

export const baseSource = "";

export default {
  url:DOWN_URL,
  baseURL: BASE_URL,
  timeout: 15000,
  headers: {
    ...DEFAULT_X_HEADER,

    "Accept-Language": "zh, en-US; q=0.9, en; q=0.8",
    "X-Requested-With": "XMLHttpRequest",
  },
};

export const APPLICATION_JSON = DEFAULT_JSON_HEADER;
// export const LICENSEBASE_URL = LICENSE_URL;
