import apiManager from './manager/api-manager'

export default {
    
  clientSideKey(){
      const data = {
          url: `/api/google/maps/v1/client-side-key`,
      }
      return apiManager.get(data)
  }
}
