import qs from 'querystring'

function parseFormData(body) {
  const formData = new FormData()
  // let keys = Object.keys(body)
  for (let key in body) {
    let item = body[key];
    // _.isObject() 
    if(typeof item.isFile !== 'undefined' && item.name) {
      formData.append(key, item, item.name)
    }else{
      formData.append(key, item)
    }

    return formData;
  }
}
export default function(config) {
	const method = config.method.toLowerCase();
  const params = config.body;

  if (method === 'get') {
    config.params = config.body;
    return config;
  }

  const contentType = config.headers['Content-Type'];
  const isUrlencode = !contentType || contentType.indexOf('x-www-form-urlencoded') !== -1;
  if (isUrlencode) {
    config.data = qs.stringify(params);
  } else if (contentType.indexOf('mutipart/form-data') !== -1) {
    config.data = parseFormData(params)
  } else {
    config.data = params;
  }

  return config
}