import axios from 'axios'
import CONFIG from './config'
import errorUtils from '@/utils/errorUtils'
import paramsInterceptor from '../interceptors/params'
axios.interceptors.request.use((config) => paramsInterceptor(config))
// axios.defaults.withCredentials = true
const TOKEN_KEY = 'jb-authorization';

class ApiManager {
  /**o
   * 網路請求：GET方法
   * @param {Object} data 傳入資料(url, params, header ···)
   */
  get(data) {
    data.method = 'get'
    return this._sendRequest(data)
  }

  /**
   * 網路請求：POST方法
   * @param {Object} data 傳入資料(url, params, header ···)
   */
  post(data) {
    data.method = 'post'
    return this._sendRequest(data)
  }

  put(data) {
    data.method = 'put'
    return this._sendRequest(data)
  }

  delete(data) {
    data.method = 'delete'
    return this._sendRequest(data)
  }


  _sendRequest(data) {
    let config = Object.assign({}, CONFIG); //一定要複製一個,避免汙染全域性物件
    config.headers = data.headers ? data.headers : config.headers;
    config.headers[TOKEN_KEY] = sessionStorage.getItem(TOKEN_KEY);
    config.method = data.method;
    config.url = data.url;
    config.body = data.params; //引數由攔截器分配,具體程式碼在 src/interceptors/params.js
    axios.defaults.withCredentials = true;

    return axios(config).then(res => this.onSuccess(res)).catch(err => this.onFailure(err));
  }

  onSuccess(response) {
    
    setTimeout(()=>{
    },500)
    const result = response.data;
    const token = response.headers[TOKEN_KEY];
    if(token){
      sessionStorage.setItem(TOKEN_KEY, token)
    }
    if (result && result.code == 0) {
      return Promise.resolve(result);
    }
    //處理業務錯誤
    const apiError = errorUtils.parseErrorFromResult(response);
    if (apiError.code === 1) {  // 如果登入過期了或者未登入則跳轉到登入頁面。
    }
    return Promise.reject(apiError);
  }

  onFailure(error) {
    // errorUtils.showError(error);
    if (error.type) {
      /**
       * 如果error知道了確切的型別,表明是從onSuccess
       * 的Promise reject過來的,因此不需要再次判斷錯誤型別
       */
      return Promise.reject(error)
    }

    //處理網路錯誤
    const networkError = errorUtils.parseError(error);
    return Promise.reject(networkError);
  }
}

export default new ApiManager()
